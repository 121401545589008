<template>
    <div class="grid grid-cols-2 gap-20 tablet:gap-10 mobile:grid-cols-1 mobile:gap-6">
        <div class=" divide-y divide-grey-200">
            <div v-for="item in items" class="w-full cursor-pointer" @click="selectedItem = item">
                <div class="flex items-center justify-between w-full py-4 mobile:py-2">
                    <span class="text-black font-medium text-md tablet:text-base">{{translate(variant + '_' + item + '_title')}}</span>
                    <i v-if="selectedItem === item" class="far fa-angle-up pr-4 "></i>
                    <i v-else class="far fa-angle-down pr-4 "></i>
                </div>

     
               <p class="text-grey-800 transition-all ease-in my-2 mb-6 mobile:mb-2 mobile:text-sm" v-show="selectedItem === item">
                   {{translate(variant + '_' + item + '_desc')}}
               </p>
            </div>
        </div>
        <div class="bento-box rounded-[24px] overflow-clip mobile:h-64 dotted-bg p-0">
            <div>
                <img class="w-full" :src="getImageUrl(selectedItem)" :alt="translate(variant + '_' + selectedItem + '_title')" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {Language} from "../../types/dto/Language";

enum Variant {
    MONITORING = "monitoring",
    PRODUCT_INFORMATION = "product-information"
}

export default defineComponent({
    name: "marketing-accordion",
    props: {
        language: {
            type: String as () => Language,
            required: true
        },
        variant: {
            type: String as () => Variant,
            required: true
        }
    },
    data() {
        const record: Record<Variant, string[]> = {
            [Variant.PRODUCT_INFORMATION]: [
                'management',
                'network',
                'ownership',
                'timeline'
            ],
            [Variant.MONITORING]: [
                'up-to-date',
                'updates',
                'lists',
                'mailbox',
                'screening'
            ],
        };
        const items = record[this.variant];
        return {
            items,
            selectedItem: items[0],
        }
    },
    methods: {
        getImageUrl(item: string): string {
            return `/v2/images-new/accordion/${this.variant}/${item}.${this.language}.svg`;
        }
    }
});
</script>