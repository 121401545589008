interface Number {
    formatThousandSeparator(): string;
    formatVat(): string;
    formatPercentage(): string;
    formatPrice(digits: number): string;
    isValidVat(): boolean;
}

Number.prototype.formatThousandSeparator = function (): string {
    return this
        .toString()
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

Number.prototype.formatVat = function (): string {
    return this
        .toString()
        .padStart(10, "0")
        .replace(/^(\d{4})(\d{3})(\d{3})$/, 'BE $1.$2.$3');
}

Number.prototype.formatPercentage = function (): string {
    return `${Math.round((this as number) * 10000)/100}%`;
}

Number.prototype.formatPrice = function (digits: number = 2): string {
    return '€ ' + this
        .toFixed(digits)
        .replace(".", ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

Number.prototype.isValidVat = function (): boolean {
    const vat = (this as number);

    if (vat < 100000000 || vat > 1999999999)
        return false;

    const first8Digits = Math.floor(vat / 100);
    const last2Digits = vat - first8Digits * 100;

    return first8Digits % 97 + last2Digits === 97;
}