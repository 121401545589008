import Ajax from "../../lib/Ajax";
import Toast from "../../lib/Toast";
import Translate from "../../mixins/translate";
import ProspectingHero from "./prospecting-hero.vue";
import MarketingAccordion from "./marketing-accordion.vue";
import {createVueInstance} from "../../vue-root";

createVueInstance({
    name: 'static-page',
    components: {
        ProspectingHero,
        MarketingAccordion
    },
    mixins: [Translate],
    data() {
        return {
            cookieValue: "" as string,
            sent: false
        }
    },
    computed: {
        cookiesAllowed(): boolean {
            return this.cookieValue === "true";
        }
    },
    methods: {
        writeCookie(): void {
            let d = new Date();
            d.setTime(d.getTime() + 60000);

            const expires = "expires=" + d.toUTCString();
            document.cookie = "cookiecheck=true;" + expires + ";path=/";
        },
        getCookie(): string {
            const name = "cookiecheck=";
            const decodedCookie = decodeURIComponent(document.cookie);
            const ca = decodedCookie.split(';');

            for (let i = 0; i < ca.length; i++) {
                let c = ca[i].trim();
                while (c.charAt(0) === ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) === 0) {
                    return c.substring(name.length, c.length);
                }
            }

            return "";
        },
        showCookieModal() {
            this.store.openModal(this.store.modals.COOKIE_BANNER);
        },
        async contactMe() {
            await Ajax.postAsync("/ajax/contact/payment-experience");
            Toast.success(this.translate("success_message_contact"));
            this.sent = true;
        }
    },
    created() {
        this.writeCookie();
    },
    mounted() {
        this.cookieValue = this.getCookie();
    }
});