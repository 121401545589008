

interface String {
    contains(input: string): boolean;
    toKebabCase(): string;
    toTitleCase(): string;
    toCamelCase(): string;
    removeBrackets(): string;
    truncate(characters: number): string;
    trim_left(input: string): string;
    trim_right(input: string): string;
    isValidVat(): boolean;
    isValidEmailAddress(): boolean;
    isValidPhoneNumber(): boolean;
}

String.prototype.contains = function (str: string): boolean {
    str = str.replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();

    return this
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .includes(str);
};

String.prototype.toKebabCase = function(): string {
    return this
    .replace(/\//g, '')
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, '-')
    .toLowerCase();
}

String.prototype.truncate = function (characterLimit: number): string {
    if (this.length > characterLimit)
        return this.substring(0, characterLimit-3) + '...';
    else
        return this.toString();
}

String.prototype.removeBrackets = function (): string {
    return this.replace(/\(.+\)/g, '');
}

String.prototype.toCamelCase = function() {
    return this
        .replace(/\s(.)/g, function($1) { return $1.toUpperCase(); })
        .replace(/\s/g, '')
        .replace(/^(.)/, function($1) { return $1.toLowerCase(); });
}

String.prototype.toTitleCase = function() {
    return this.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

String.prototype.trim_left = function (input: string): string {
    return this.replace(new RegExp("^" + input, 'g'), '');
}

String.prototype.trim_right = function (input: string): string {
    return this.replace(new RegExp(input + "$", 'g'), '');
}

String.prototype.isValidVat = function (): boolean {
    const input = Number(this.match(/\d+/g)?.join(''));
    
    return input.isValidVat();
}

String.prototype.isValidEmailAddress = function (): boolean {
    return /^([-_.a-z0-9]+(\+[-_.a-z0-9]+)?@([a-z0-9][-_.a-z0-9]*\.[a-z]{2,}))(?:\s*;.*)?$/i.test(this as string);
}
String.prototype.isValidPhoneNumber = function (): boolean {
    return /^\s*\+?(?:[-./() ]*\d){9,15}\s*$/.test(this as string);
}