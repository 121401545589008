import 'vite/modulepreload-polyfill';
import './sentry';
import './extensions/date.extensions';
import './extensions/string.extensions';
import './extensions/number.extensions';
import './extensions/array.extensions';
import {initVueInstance} from "./vue-root";

const behaviors = import.meta.glob('./behaviors/*.ts')
const types = import.meta.glob('./types/**/*.ts')

for(const t in types) {types[t]();}
for(const b in behaviors) {behaviors[b]();}

document.querySelectorAll("[vue-shared-root]").forEach(el => {
    initVueInstance({
        name: 'shared-root',
        el: el
    }).mount(el);
});